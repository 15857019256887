import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { __log, _isDev, _isFeDev, _isLab, _isStaging, _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _timeout } from '@shared/aux_helper_functions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GlobalAlertService } from './ian-core-singleton.service';
import { _getBrandConfigLS, _setBrandConfigLS } from './ian-prepareBrandCustomizationV2';
const JSON5 = require('json5');

const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';

export enum FeatureFlagsKeysEnum {
  'DEV-01__SHOW_DATA_QUALITY_REPORT' = 'DEV-01__SHOW_DATA_QUALITY_REPORT',
  //
  'PRO-540__FORZAR_ACTIVAR_NOTIFICACIONES' = 'PRO-540__FORZAR_ACTIVAR_NOTIFICACIONES',
  'CAT-214__ESPACIOS_BLOQUEADOS' = 'CAT-214__ESPACIOS_BLOQUEADOS',
  'CAT-469__ACCIONES_MASIVAS' = 'CAT-469__ACCIONES_MASIVAS',
  'FeatureFlag__CAT-250' = 'FeatureFlag__CAT-250',
  'FeatureFlag__CAT-663' = 'FeatureFlag__CAT-663',
  'CAT-388__NUEVA_PANTALLA_GENERACION_TEMPLATE' = 'CAT-388__NUEVA_PANTALLA_GENERACION_TEMPLATE',
  'DES-2998__PLANOGRAMAS_ITEM_SCOPE' = 'DES-2998__PLANOGRAMAS_ITEM_SCOPE',
  'DES-3172__CONFIG_DATE_FORMAT_ON_SETTING_PANEL' = 'DES-3172__CONFIG_DATE_FORMAT_ON_SETTING_PANEL',
  'DES-3434__SUGERENCIAS_CUALQUIER_NIVEL_DE_CATEGORIA' = 'DES-3434__SUGERENCIAS_CUALQUIER_NIVEL_DE_CATEGORIA',
  'DES-3758__ASYNC-IMPORT-VENDOR-COSTS' = 'DES-3758__ASYNC-IMPORT-VENDOR-COSTS',
  'DES-3662__VIEW_COMPETITION_PRICE' = 'DES-3662__VIEW_COMPETITION_PRICE',
  'DES-3XXX__AUTOCOMPLETE_ITEM' = 'DES-3XXX__AUTOCOMPLETE_ITEM',
  'DES-3898__NEW-DECISION-SUMMARY' = 'DES-3898__NEW-DECISION-SUMMARY',
  'DES-4316__CONFIG_DE_IMPORTADORES_DESDE_BE' = 'DES-4316__CONFIG_DE_IMPORTADORES_DESDE_BE',
  'DES-4362__STORE_REPORT_PDV' = 'DES-4362__STORE_REPORT_PDV',
  'DES-4510__SPC-FEATURES-PARA-POC-SODIMAC' = 'DES-4510__SPC-FEATURES-PARA-POC-SODIMAC',
  'DES-4971__LISTADO-DE-MATERIAL-POP' = 'DES-4971__LISTADO-DE-MATERIAL-POP',
  'GOD-188__IMPLEMENTATION-MODE' = 'GOD-188__IMPLEMENTATION-MODE',
  'CAT-195__SHOW_ONLY_SUGGESTIONS_FILTER' = 'CAT-195__SHOW_ONLY_SUGGESTIONS_FILTER',
  'PREC-287__TAB_DE_EQUIVALENCIAS_EN_IM' = 'PREC-287__TAB_DE_EQUIVALENCIAS_EN_IM',
  'PREC-221__REVISION_DEENDPOINT_VIEJO_DE_ATTR' = 'PREC-221__REVISION_DEENDPOINT_VIEJO_DE_ATTR',
  'DEMO-XXXX__SHOW_IMV2_REPPORT_LIST' = 'DEMO-XXXX__SHOW_IMV2_REPPORT_LIST',
  'PRO-357__NESTED_TAGS_IN_SCOPES' = 'PRO-357__NESTED_TAGS_IN_SCOPES',
  'PREC-620__PRODUCTOS_NUEVOS' = 'PREC-620__PRODUCTOS_NUEVOS',
  'FeatureFlag__PRO-767' = 'FeatureFlag__PRO-767',
  'FeatureFlag__PREC-857' = 'FeatureFlag__PREC-857',
  'FeatureFlag__PREC-864' = 'FeatureFlag__PREC-864',
}

export type FeatureFlagsModel = {
  [key in FeatureFlagsKeysEnum]: boolean;
};

const _global = window as any;

const _getSafeKeys = obj => {
  if (obj == null) return [];
  return Object.keys(obj);
};

@Injectable({
  providedIn: 'root',
})
export class DevSettingsService {
  private FF_level1_env: FeatureFlagsModel = null;
  private FF_level2_brandCustomization: FeatureFlagsModel = null;
  private FF_level3_userLocalStorage: FeatureFlagsModel = null;

  private FF_default_values: FeatureFlagsModel = null;
  private FF_final_values: FeatureFlagsModel = null;

  private valKey = _keyVer + 'userFeatureFlags';
  private showDevSetings = false;
  private userRole = null;
  private userData = null;

  constructor(private http: HttpClient, private globalAlertService: GlobalAlertService, private oidcSecurityService: OidcSecurityService) {
    this.FF_level1_env = environment.featureFlags;

    this.oidcSecurityService.getUserData().subscribe(async (data: any) => {
      if (data?.role?.length && this.userRole == null) {
        this.userRole = data?.role;
        this.userData = data;
        this.setGlobalMethods();
      }
    });
  }

  public __canUseDevMode() {
    let email = this.userData?.email;

    if (_isDev() || _isLab() || _isStaging()) {
      return true;
    }

    if (email && (email.includes('@iantech.net') || email.includes('@prismaretail.ai'))) {
      return true;
    }

    return false;
  }

  public initFeatureFlags(brandConfig = null) {
    this.oidcSecurityService.getUserData().subscribe(async (data: any) => {
      if (data?.role?.length) {
        if (this.userRole == null) this.userRole = data?.role;
        this.internalInitFeatureFlags(brandConfig);
      }
    });
  }

  private internalInitFeatureFlags(brandConfig = null) {
    const featuresEnabled_featureFlags = brandConfig?.featuresEnabled?.featureFlags;

    if (this.FF_level2_brandCustomization != null || this.FF_level1_env == null) return;

    this.FF_level2_brandCustomization =
      brandConfig != null
        ? this.assingValidFeatureFlagsKeysValues(featuresEnabled_featureFlags) ?? this.FF_level2_brandCustomization
        : null;

    this.FF_level3_userLocalStorage = this.loadUserStorageFeatureFlagsValues();

    this.rebuildFeatureFlagsValues();

    if (true && brandConfig != null)
      _log('\n\nINIT initFeatureFlags OK', {
        userRole: this.userRole,
        userLocalStorage: this.FF_level3_userLocalStorage,
        mode: this.__canUseDevMode(),
      });
  }

  private setGlobalMethods() {
    if (!this.__canUseDevMode()) return;

    let $window: any = window;

    if (true && $window) {
      $window.__emulateEndPointError = () => this.__emulateEndPointError();
      $window.__resetLocalStorage = () => this.__resetLocalStorage(true);
      $window.__resetFeatureFlagsUserValues = () => this.__resetFeatureFlagsUserValues(true);
      $window.__setCanShowDevSettings = () => this.__setCanShowDevSettings(true);
      $window.__setLocalStorage = ($obj: string | Object, reset = true) => this.__setLocalStorage($obj, reset);
      $window.__setBrandConfigLS = ($obj: string | Object, reset = true) => this.__setBrandConfigLS($obj, reset);
    }
  }

  private assingValidFeatureFlagsKeysValues(_obj, force = false): any {
    if (_obj == null) return null;

    let rv = {};
    const keysEnum = this.getValidFeatureFlagsKeys();

    _getSafeKeys(_obj).forEach(key => {
      if (force || keysEnum.includes(key)) {
        rv[key] = !!_obj[key];
      }
    });

    return rv;
  }

  private loadUserStorageFeatureFlagsValues() {
    if (!this.__canUseDevMode()) return null;

    let value = localStorage.getItem(this.valKey);

    if (value) {
      try {
        let rv = JSON5.parse(value);
        _log(['loadUserStorageValues'], rv);
        if (rv && _getSafeKeys(rv).length > 0) this.__setCanShowDevSettings(true);
        return rv;
      } catch (e) {
        localStorage.removeItem(this.valKey);
      }
    }

    return null;
  }

  private rebuildFeatureFlagsValues() {
    this.FF_default_values = _cloneDeep({ ...this.FF_level1_env, ...this.FF_level2_brandCustomization });
    this.FF_final_values = _cloneDeep({ ...this.FF_default_values, ...this.FF_level3_userLocalStorage });

    if (_global) {
      _global._info = _global._info ? _global._info : {};
      _global._info._featureFlags = this.FF_final_values;
      _global._info._featureFlagsValidList = this.getValidFeatureFlagsKeys();
    }

    if (true) _log(['FeatureFlags'], this.FF_final_values);
  }

  private getValidFeatureFlagsKeys() {
    return _getSafeKeys(FeatureFlagsKeysEnum);
  }

  private async restartApp(reset) {
    this.rebuildFeatureFlagsValues();

    _log('[restartApp]');

    if (reset) {
      (window as any)?.location?.reload?.();
    }
  }

  public hasFeatureFlag(key: FeatureFlagsKeysEnum) {
    return this.FF_final_values?.[key] != undefined ? !!this.FF_final_values?.[key] : undefined;
  }

  public get __canShowDevSettings(): boolean {
    if (!this.__canUseDevMode()) return false;

    return this.showDevSetings;
  }

  public __getAllEnabledFeatureFlagsValues() {
    return _getSafeKeys(this.FF_final_values).filter(e => this.FF_final_values?.[e] === true);
  }

  public __setCanShowDevSettings(val = true) {
    if (!this.__canUseDevMode()) return false;

    this.showDevSetings = !!val;
  }

  //Para saber el valor default de una KEY
  public __getFeatureFlagsDefault(key: string) {
    return this.FF_default_values?.[key] === true;
  }

  public __getAllFeatureFlagsValues() {
    return { ...this.FF_final_values };
  }

  public __getDefaultValues() {
    return { ...this.FF_default_values };
  }

  public __getUserValues() {
    return { ...this.FF_level3_userLocalStorage };
  }

  public __setFeatureFlagsUserValues(obj, reset = false) {
    if (obj == null || !this.__canUseDevMode()) return;

    let rv = {} as any;
    _getSafeKeys(obj).forEach(key => {
      if (obj[key] !== this.FF_default_values?.[key] || obj[key] !== this.FF_final_values?.[key]) {
        rv[key] = obj[key];
      }
    });
    rv = this.assingValidFeatureFlagsKeysValues(rv);

    if (!(_getSafeKeys(rv).length > 0)) {
      this.__resetFeatureFlagsUserValues(reset);
      return;
    }

    localStorage.setItem(this.valKey, JSON.stringify(rv));
    this.FF_level3_userLocalStorage = rv;
    _log('[__setUsersValue]', obj, rv);

    this.restartApp(reset);
  }

  public __resetFeatureFlagsUserValues(reset = true) {
    _log('[__resetUsersValue]');

    this.FF_level3_userLocalStorage = null;

    localStorage.removeItem(this.valKey);
    this.restartApp(reset);
  }

  public __hasLocalStorage(): boolean {
    if (this.FF_level3_userLocalStorage == null || !this.__canUseDevMode()) return false;

    return !!(_getSafeKeys(this.FF_level3_userLocalStorage)?.length > 0);
  }

  public __emulateEndPointError() {
    if (!this.__canUseDevMode()) return false;

    _log('[emulateEndPointError]');

    this.http.get('/api/fakeError').subscribe(data => _log(data));
  }

  public __resetLocalStorage(reset = true) {
    let ls = _cloneDeep(window?.localStorage);
    if (ls == null) return;

    let validKeys = [
      _keyVer + 'code_verifier',
      _keyVer + 'authorizationDataIdToken',
      _keyVer + 'userData',
      _keyVer + 'storage_silent_renew_running',
      _keyVer + 'userData_permission',
      _keyVer + 'codeUrl',
      _keyVer + 'authNonce',
      _keyVer + 'authorizationResult',
      _keyVer + 'authStateControl',
      _keyVer + 'session_state',
      _keyVer + 'authorizationData',
      _keyVer + '_isAuthorized',
      _keyVer + '__token',
    ];

    Object.keys(ls).forEach(key => {
      if (!validKeys.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    this.restartApp(reset);
  }

  public __setLocalStorage($obj: string | object, reset = true) {
    if (!this.__canUseDevMode()) return;

    let rv: any = $obj === 'string' ? JSON.parse($obj) : $obj;

    if (true) _log('[__setLocalStorage]', $obj, rv);

    Object.keys(rv).forEach(key => {
      let keyVal = rv[key];
      let val = keyVal !== null && typeof keyVal === 'object' ? JSON.stringify(rv[key]) : keyVal;
      localStorage.setItem(key, val);
    });

    this.restartApp(reset);
  }

  public __setBrandConfigLS(brandConfig, reset = true) {
    if (!this.__canUseDevMode()) return;

    //__setBrandConfigLS({ brandLogoAsset: "./assets/brands/oxxo.png" })
    //__setBrandConfigLS({ featuresEnabled: { blocksEnabled: { 'PRISMA-ASSISTANT': false } } })
    _setBrandConfigLS(brandConfig);
    this.restartApp(reset);
  }

  public __getBrandConfigLS() {
    return _getBrandConfigLS();
  }
}
